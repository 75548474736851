import {
  makeStyles,
  Box,
  Container,
  Typography,
  TextField,
  FormHelperText,
  Grid,
  Button,
  IconButton,
} from "@material-ui/core";
import React, { useState, useContext } from "react";
import { Form, Formik } from "formik";
import * as yep from "yup";
import { useHistory } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import "react-phone-input-2/lib/style.css";
import Logo from "src/component/Logo";
import { dataPostHandler } from "src/services/index";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { toast } from "react-hot-toast";
import CloseIcon from "@material-ui/icons/Close";
import { UserContext } from "src/context/User";

import moment from "moment";

const useStyles = makeStyles((theme) => ({
  mainSignupFrom: {
    padding: "30px 0px",
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1111",
    "& h4": {
      fontSize: "30px",
      "@media(max-width:767px)": {
        fontSize: "20px",
      },
    },
    "& .logoSection": {
      paddingBottom: "64px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
  },

  HeaddingBox: {
    marginTop: "5px",
    "@media(max-width:960px)": {
      textAlign: "center",
    },
  },
  formikBox: {
    marginTop: "15px",
  },

  Register: {
    "& h4": {
      // color: "#fff",
    },
    "@media(max-width:960px)": {
      textAlign: "center",
    },
  },

  FormInputField: {
    border: "none",
    color: "#fff",
    "& input": {
      marginTop: "8px",
      marginBottom: "4px",
      // color: "#fff",
    },

    "& .MuiOutlinedInput-input": {
      borderRadius: "0px",
    },

    "& svg": {
      // color: "#fff",
      cursor: "pointer",
    },
  },
  footerTxt: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    flexDirection: "column",
    "& h4": {
      textAlign: "center",
      color: "#e08638",
      fontSize: "14px",
      cursor: "pointer",
      "@media(max-width:768px)": {
        fontSize: "14px",
      },
    },
  },
  IconButton: {
    position: "absolute",
    top: "-100px",
    right: "5px",
    zIndex: "999",
    [theme.breakpoints.down("sm")]: {
      top: "-25px",
      right: "px",
    },
  },
}));

export default function ForgotPassword({
  setOpenLogin,
  setOpenForgot,
  setVerify,
}) {
  const classes = useStyles();
  const user = useContext(UserContext);

  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errmsg, setErrMsg] = useState("");
  const formInitialSchema = {
    email: "",
  };
  const formValidationSchema = yep.object().shape({
    email: yep
      .string()
      .email("Please enter a valid email address.")
      .max(256, "Email must be less than 256 characters.")
      .required("Email is required."),
  });
  const handleFormSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      window.sessionStorage.setItem("email", values.email);
      const response = await dataPostHandler("forgotPassword", {
        email: values.email,
      });
      if (
        response?.data !== undefined &&
        response?.data?.statusCode !== undefined
      ) {
        if (response.data.statusCode == 200) {
          // history.push("/verify-otp");
          setVerify(true);
          setOpenForgot(false);
          toast.success(response.data.responseMessage);
          user.setEndtime(moment().add(3, "m").unix());
        } else {
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false);
        setErrMsg(response);
      }
    } catch (error) {
      console.log("error", error);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      className={
        themeSeeting?.settings?.theme === "DARK" ? "mainbox" : "mainbox1"
      }
    >
      <Box className={classes.mainSignupFrom}>
        <Box display="flex" justifyContent="flex-end" width="100%">
          <IconButton
            // className="closeIcon"
            onClick={() => setOpenForgot(false)}
            className={classes.IconButton}
          >
            <CloseIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </Box>
        <Container maxWidth="lg">
          <Box className={classes.Register}>
            <Typography
              variant="h4"
              color="primary"
              style={{ whiteSpace: "pre" }}
            >
              Forgot Password
            </Typography>
          </Box>

          <Box className={classes.HeaddingBox}></Box>
          <Box className={classes.formikBox}>
            <Formik
              initialValues={formInitialSchema}
              initialStatus={{
                success: false,
                successMsg: "",
              }}
              validationSchema={formValidationSchema}
              onSubmit={(values) => handleFormSubmit(values)}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
                setFieldValue,
              }) => (
                <Form className={classes.root}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <TextField
                        variant="outlined"
                        type="email"
                        placeholder="Email address"
                        fullWidth
                        name="email"
                        id="email"
                        inputProps={{
                          maxLength: 151,
                        }}
                        value={values.email}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={classes.FormInputField}
                      />
                      {values.email.length > 150 && (
                        <FormHelperText error>
                          Please should not exceed 150 characters
                        </FormHelperText>
                      )}
                      <FormHelperText error className={classes.helperText}>
                        {touched.email && errors.email}
                      </FormHelperText>
                    </Grid>

                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box mb={2} mt={2}>
                        {errmsg !== "" && (
                          <FormHelperText error>{errmsg}</FormHelperText>
                        )}
                        <Button
                          type="submit"
                          variant="contained"
                          fullWidth
                          color="primary"
                          style={{ marginTop: "2px" }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? (
                            <>
                              Please wait... <ButtonCircularProgress />
                            </>
                          ) : (
                            "Submit"
                          )}
                        </Button>
                      </Box>
                      <Box className={classes.footerTxt}>
                        <Typography
                          variant="h4"
                          color="primary"
                          style={{ textDecoration: "underline" }}
                          onClick={() => {
                            setOpenLogin(true);
                            setOpenForgot(false);
                          }}
                        >
                          Login
                        </Typography>
                      </Box>

                      <div className="shade14"></div>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
