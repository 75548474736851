import React, { useState } from "react";
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  TextField,
  FormControl,
  FormHelperText,
  Button,
  InputAdornment,
} from "@material-ui/core";
import axios from "axios";
import { toast } from "react-hot-toast";

import ApiConfig from "src/ApiConfig/ApiConfig";
import * as yep from "yup";
import { Formik, ErrorMessage, Form } from "formik";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
const useStyles = makeStyles((theme) => ({
  StayInTouchBox: {
    padding: "40px 0px 10px",
    background: "url('images/stay_banner.png')",
    position: "relative",
    overflow: "hidden",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    backgroundSize: "cover",
    zIndex: "1",
    "&:before": {
      top: "0",
      left: "0px",
      width: "100%",
      height: "100%",
      content: "' '",
      position: "absolute",
      background: "rgba(0, 0, 0, 0.4)",
      zIndex: "-1",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "60px 0px",
    },
    "& .shape": {
      position: "absolute",
      top: "70px",
      left: "100px",
    },
    "& .shape1": {
      position: "absolute",
      top: "60px",
      left: "180px",
    },
    "& .shape2": {
      position: "absolute",
      bottom: "-25px",
      left: "44%",
    },
    "& .shape3": {
      right: "0px",
      bottom: 0,
      position: "absolute",
      width: "65px",
    },
    "& .shape4": {
      position: "absolute",
      right: "15%",
      top: "25px",
    },
    "& .shape5": {
      position: "absolute",
      right: "8%",
      bottom: "25px",
    },
    "& .emailBox": {
      display: "flex",
      "& .MuiOutlinedInput-input": {
        color: "#fff",
      },

      "& button": {
        background: "rgba(255, 255, 255, 0.1)",
        borderRadius: "10px",
        height: "50px",
        padding: "0 45px",
        marginLeft: "15px",
        color: "#ffffff",
        [theme.breakpoints.only("xs")]: {
          padding: "0 25px",
          marginLeft: "8px",
          fontSize: "12px !important",
        },
      },
    },
    "& input": {
      backgroundColor: "rgba(217, 217, 217, 0.05)",
      borderRadius: "10px",
      border: " none",
      height: "50px",
      padding: "0 15px",
    },
    "& h2": {
      color: "#ffffff",
      fontFamily: "'ClashDisplay-Extralight'",

      "& span": { fontFamily: "'ClashDisplay-Medium'" },
    },
    "& p": {
      color: "rgba(255, 255, 255, 0.6)",
      maxWidth: "300px",
      padding: "15px 0px",
    },
  },
}));

export default function BestSeller(props) {
  const classes = useStyles();
  const [confirmation, setConfirmation] = useState(false);

  const [isUpdating, setIsUpdating] = useState(false);

  const formInitialSchema = {
    email: "",
    link: "",
  };
  const formValidationSchema = yep.object().shape({
    email: yep
      .string()
      .required("Email address is required field.")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please enter a valid Email address"
      ),
  });

  const handleFormSubmit = async (values) => {
    try {
      axios({
        method: "PUT",
        url: ApiConfig.userSubscribe,

        params: {
          email: values.email,
          // link: "https://node.12-jyotirlingas.com/subscribehovr",
          link: "https://12jyotirlinga-react.mobiloitte.org/subscribehovr",
        },
      })
        .then(async (response) => {
          if (response.data.statusCode === 200) {
            toast.success("We have sent an email. please verify");

            setIsUpdating(false);
          } else if (response.statusCode === 409) {
            toast.success(response.data.responseMessage);
            setIsUpdating(false);
          } else {
            setIsUpdating(false);
            toast.success("We have sent email please verify");
            setConfirmation(true);
          }
        })
        .catch((error) => {
          console.log(error.message);
          if (error.response) {
            toast.error(error.response.data.responseMessage);
          }
          setIsUpdating(false);
        });
    } catch (err) {
      console.error(err.response);
      //  setIsLoading(false);
    }
  };
  return (
    <Box className={classes.StayInTouchBox} align="center">
      <Container maxWidth="sm">
        <Typography variant="h2">
          Stay In <span>Touch</span>
        </Typography>
        <Typography variant="body2">
          Get Notified about important SubsocicalUpdates.
        </Typography>

        <Box>
          <Formik
            initialValues={formInitialSchema}
            initialStatus={{
              success: false,
              successMsg: "",
            }}
            validationSchema={formValidationSchema}
            onSubmit={(values, { resetForm }) =>
              handleFormSubmit(values, resetForm({ values: "" }))
            }
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Box className="emailBox">
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      name="email"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      id="outlined-basic"
                      placeholder="Enter your email"
                      fullWidth
                    />
                    <FormHelperText error style={{ color: "rgb(239 18 18)" }}>
                      {touched.email && errors.email}
                    </FormHelperText>
                  </FormControl>
                  <Button
                    type="submit"
                    className="searchBtn"
                    disabled={isUpdating}
                    variant="contained"
                  >
                    Subscribe{isUpdating && <ButtonCircularProgress />}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Container>
    </Box>
  );
}
