/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import { DiHtml5Multimedia } from "react-icons/di";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import GroupIcon from "@material-ui/icons/Group";
import PropTypes from "prop-types";
import Logo from "src/component/Logo";
import SettingsContext from "src/context/SettingsContext";
import {
  Box,
  Drawer,
  Hidden,
  List,
  ListSubheader,
  makeStyles,
  Dialog,
  Typography,
  Button,
} from "@material-ui/core";
import { VscFeedback } from "react-icons/vsc";
import {
  FaTachometerAlt,
  FaQuestionCircle,
  FaCreativeCommonsBy,
} from "react-icons/fa";
import NavItem from "./NavItem";
import DashboardIcon from "@material-ui/icons/Dashboard";
import { AiOutlineControl } from "react-icons/ai";
import { DiJqueryLogo } from "react-icons/di";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { UserContext } from "src/context/User";
import { RiAdminLine } from "react-icons/ri";
import { TbUserSearch } from "react-icons/tb";
import { Category } from "@material-ui/icons";
import { BsCardList, BsCollectionFill } from "react-icons/bs";
import { MdSpaceDashboard, MdContentPaste } from "react-icons/md";

const sections = [
  {
    items: [
      {
        title: "Marketplace",
        icon: BsCardList,
        href: "/explore",
      },
      {
        title: "Collections",
        icon: BsCollectionFill,
        href: "/collections",
      },
      {
        title: "Creators",
        icon: FaCreativeCommonsBy,
        href: "/creators-list",
      },
      {
        title: "My Collection",
        icon: FaTachometerAlt,
        href: "/my-collections",
      },
      // {
      //   title: "My Brand",
      //   icon: GiSwordBrandish,
      //   href: "/my-brandlist",
      // },
    ],
  },
];

const sectionsAfterLogin = [
  {
    items: [
      {
        title: "My Activity",
        icon: DashboardIcon,
        href: "/activity",
      },
      {
        title: "Metaverse Activity",
        icon: DashboardIcon,
        href: "/metaverse-activity",
      },
    ],
  },
];

const sectionsAdmin = [
  {
    items: [
      {
        title: "Dashboard",
        icon: MdSpaceDashboard,
        href: "/dashboard",
      },
      {
        title: "User Management",
        icon: RiAdminLine,
        href: "/user-management",
      },
      {
        title: "Sub Admin",
        icon: TbUserSearch,
        href: "/subadmin-list",
      },

      {
        title: "NFT Management",
        icon: ShoppingCartIcon,
        href: "/nft-management",
      },
      {
        title: "Revenue Management",
        icon: DiJqueryLogo,
        href: "/revenue-management",
      },
      {
        title: "KYC Management",
        icon: DiJqueryLogo,
        href: "/kyc-management",
      },
      {
        title: "Control",
        icon: AiOutlineControl,
        href: "/control",
      },
      {
        title: "Subscribers",
        icon: GroupIcon,
        href: "/subscribers",
      },
      {
        title: "Category",
        icon: Category,
        href: "/category",
      },
      {
        title: "Static Content",
        icon: MdContentPaste,
        href: "/static-content",
      },
      {
        title: "Feedback",
        icon: VscFeedback,
        href: "/feedback-list",
      },
      {
        title: "Media",
        icon: DiHtml5Multimedia,
        href: "media-list",
      },
      {
        title: "Faq Management",
        icon: FaQuestionCircle,
        href: "faq-list",
      },
    ],
  },
];

const sectionsBelow = [
  {
    items: [
      {
        // title: "Logout",
        icon: ExitToAppIcon,
        href: "/terms-and-condition",
      },
      // {
      //   title: "Privacy Policy",
      //   //icon: PieChartIcon,
      //   href: "/privacy-policy",
      // },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    background: theme.palette.background.default,
    backdropFilter: "blur(44px)",
  },
  desktopDrawer: {
    width: 256,
    top: 0,
    height: "100%",
    border: "none",
    background: theme.palette.background.blur,
    margin: "5px 5px 0px 16px",
    borderRadius: "15px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  logoicon: {
    display: "flex",
    marginTop: "16px",
    alignItems: "center",
    marginLeft: "30px",
  },
  logoutbutton: {
    justifyContent: "space-between",
    paddingLeft: 10,
    borderRadius: 0,
    width: "60px",
    textAlign: "center",
  },
  logoutDialogContent: {
    padding: "25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logoutModal: {
    width: "450px",
    [theme.breakpoints.down("xs")]: {
      width: "290px",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const user = useContext(UserContext);
  const classes = useStyles();
  const location = useLocation();
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [openLogout, setOpenLogout] = useState(false);
  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  const handleClose4 = () => {
    setAnchorEl1(null);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box pt={4}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}

          {user?.isLogin &&
            sectionsAfterLogin.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}

          {user?.isLogin &&
            user?.userData?.userType == "Admin" &&
            sectionsAdmin.map((section, i) => (
              <List
                key={`menu${i}`}
                subheader={
                  <ListSubheader disableGutters disableSticky>
                    {section.subheader}
                  </ListSubheader>
                }
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname,
                })}
              </List>
            ))}
          <Box ml={4}>
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => setOpenLogout(true)}
              style={{ whiteSpace: "pre", marginTop: "10px" }}
            >
              Logout
            </Button>
          </Box>
        </Box>
        <Box className="side_nev_Bottom">
          {sectionsBelow.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            ></List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box style={{ margin: "10px 30px 0px" }}>
            <Link to="/">
              <Logo className="logoImg" />
            </Link>
          </Box>
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          <Box style={{ margin: "10px 30px 0px" }}>
            <Link to="/">
              <Logo className="logoImg" />
            </Link>
          </Box>
          {content}
        </Drawer>
      </Hidden>
      {/* LOGOUT MODAL*/}
      <Dialog open={openLogout} onClose={() => setOpenLogout(false)}>
        <Box className={`${classes.logoutModal} newModalBorder`}>
          <Box
            className={
              themeSeeting?.settings?.theme === "DARK" ? "mainbox" : "mainbox1"
            }
          >
            <div
              className={
                themeSeeting?.settings?.theme === "DARK"
                  ? "logindarkleftModal"
                  : "loginlightModal"
              }
            ></div>
            <div
              className={
                themeSeeting?.settings?.theme === "DARK"
                  ? "loginDarktModal"
                  : "loginlightrightModal"
              }
            ></div>
            <Box className={classes.logoutDialogContent}>
              <Typography
                variant="h3"
                color="primary"
                style={{ fontSize: "25px" }}
              >
                Logout!
              </Typography>

              <Box my={2}>
                <Typography
                  variant="h4"
                  color="primary"
                  style={{ whiteSpace: "pre", fontSize: "16px" }}
                >
                  Do you want to logout?
                </Typography>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => setOpenLogout(false)}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    style={{ marginLeft: "8px" }}
                    onClick={() => {
                      setAnchorEl1();
                      user.logoutHandler();
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
