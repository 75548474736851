import React, { useContext, useState, useEffect } from "react";

import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  ListItem,
  List,
  Link,
  IconButton,
  Dialog,
} from "@material-ui/core";
// import { UserContext } from 'src/context/User'
import SettingsContext from "src/context/SettingsContext";
import { useHistory, Link as RouterLink } from "react-router-dom";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FiTwitter, FiYoutube } from "react-icons/fi";
import { AiOutlineReddit } from "react-icons/ai";
import Scroll from "react-scroll";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-hot-toast";
import Register from "src/component/Register";
import { UserContext } from "src/context/User";
import Forgot from "src/component/Forgot";

const useStyles = makeStyles((theme) => ({
  footerSection: {
    // background: theme.palette.background.card,
    position: "relative",
    padding: "50px 0px 0",
    zIndex: "2",
    overflow: " hidden",
    "& .imageGrid": {
      position: "absolute",
      display: "flex",
      zIndex: "-1",
      width: "100%",
      alignItems: "end",
      top: "-17%",
      justifyContent: "end",
      [theme.breakpoints.down("sm")]: {
        top: "28%",
        right: "-3%",
      },
      [theme.breakpoints.down("xs")]: {
        top: "30%",
        alignItems: "center",
        justifyContent: "center",
      },
      [theme.breakpoints.only("md")]: {
        top: "-18%",
        right: "-5%",
      },
      [theme.breakpoints.up("md")]: {
        top: "-18%",
        right: "0%",
      },
    },
    "& .imageBox": {
      right: "16%",
      position: "absolute",
      maxWidth: "216px",
      top: "36px",
      [theme.breakpoints.down("xs")]: {
        right: "9%",
        position: "absolute",
        top: "51%",
      },
    },
    "& .copy": {
      borderTop: "1px solid #d0d0d017",
      // padding: "10px 0",
      textAlign: "center",
      fontWeight: 300,
      fontSize: "12px",
      color: "#626262",
      "& p": {
        "& span": {
          color: "#b76722",
        },
      },
    },
    "& ul": {
      paddingLeft: "0",
      // [theme.breakpoints.down("sm")]: {
      //   marginBottom: "30px",
      // },
      // [theme.breakpoints.down("xs")]: {
      //   marginBottom: "0px",
      // },
      "& li": {
        paddingLeft: "0",
        alignItems: "center",
        color: theme.palette.text.gray,
        fontSize: "14px",
        fontWeight: "300",
        [theme.breakpoints.only("xs")]: {
          padding: "8px 0",
          fontSize: "12px",
        },
        "& svg": {
          marginRight: "10px",
          color: "#b76722",
          fontSize: "15px",
        },
      },
    },
    "& svg": {
      color: "#b76722",
      fontSize: "15px",
    },
    "& p": {
      color: theme.palette.text.gray,
      maxWidth: "231px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    "& h6": {
      [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
      },
      [theme.breakpoints.down("xs")]: {
        marginTop: "10px",
      },
    },
    "& .MuiIconButton-root": {
      padding: "0px 13px 0px 0px",
    },
    "& a": {
      color: theme.palette.text.gray,
      fontWeight: 300,
      textDecoration: "none",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      paddingLeft: "0px",
      paddingRight: "0px",
      [theme.breakpoints.only("xs")]: {
        fontSize: "12px",
      },
      "& :hover": {
        color: "#b76722",
        textDecoration: "none",
      },
    },
    "& .borderBox": {
      position: "absolute",
      left: "153px",
      top: "12px",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();
  const history = useHistory();
  const themeSeeting = useContext(SettingsContext);
  const { account, library, chainId } = useWeb3React();
  const user = useContext(UserContext);

  const tokenCheck =
    window.sessionStorage.getItem("token") &&
    window.sessionStorage.getItem("token");

  return (
    <>
      <Box className={classes.footerSection}>
        <Container maxWidth="lg">
          <img
            className="imageBox"
            width="100%"
            src={
              themeSeeting.settings.theme === "DARK"
                ? "./images/om_img.png"
                : "./images/om_img1.png"
            }
            alt="Footer"
          />

          <Grid container style={{ position: "relative" }}>
            <Grid item xs={12} sm={12} md={3}>
              <Box>
                <Box mb={2}>
                  {" "}
                  <RouterLink to="/">
                    <img
                      src={
                        themeSeeting.settings.theme === "DARK"
                          ? "./images/Logo.png"
                          : "./images/Logo2.png"
                      }
                      // src="images/Logo.png"
                      alt=""
                      style={{ width: "152px" }}
                    />{" "}
                    <br />
                  </RouterLink>
                </Box>
                <Typography variant="body1">
                  NFT marketplace with the lowest transaction fees in the world.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9}>
              <Grid container spacing={2}>
                <Grid item xs={4} sm={3}>
                  <Typography variant="h6" color="primary">
                    Web Link
                  </Typography>
                  <List>
                    <ListItem to="/collections" component={RouterLink}>
                      Collections
                    </ListItem>

                    {tokenCheck != null ? (
                      <ListItem to="/activity" component={RouterLink}>
                        My Activity
                      </ListItem>
                    ) : (
                      <ListItem
                        // onClick={() => {
                        //   history.push({
                        //     pathname: "conect-wallet",
                        //     search: "myactivity",
                        //   });
                        // }}
                        onClick={() => toast.error("Please login first")}
                        style={{ cursor: "pointer" }}
                      >
                        My Activity
                      </ListItem>
                    )}

                    {tokenCheck != null ? (
                      <ListItem to="/KYC" component={RouterLink}>
                        KYC
                      </ListItem>
                    ) : (
                      <ListItem
                        // onClick={() => setOpenSignup(true)}
                        onClick={() => toast.error("Please login first")}
                        style={{ cursor: "pointer" }}
                      >
                        KYC
                      </ListItem>
                    )}
                  </List>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <Typography variant="h6" color="primary">
                    Dashboard
                  </Typography>
                  <List>
                    {tokenCheck != null ? (
                      <ListItem to="create" component={RouterLink}>
                        Create Items
                      </ListItem>
                    ) : (
                      <ListItem
                        // onClick={() => {
                        //   history.push({
                        //     pathname: "conect-wallet",
                        //     search: "mycreate",
                        //   });
                        // }}
                        onClick={() => toast.error("Please login first")}
                        style={{ cursor: "pointer" }}
                      >
                        Create Items
                      </ListItem>
                    )}

                    {tokenCheck != null ? (
                      <ListItem to="profile" component={RouterLink}>
                        My Account
                      </ListItem>
                    ) : (
                      <ListItem
                        // to="conect-wallet"
                        // component={RouterLink}
                        // onClick={() => {
                        //   history.push({
                        //     pathname: "conect-wallet",
                        //     search: "myaccount",
                        //   });
                        // }}
                        style={{ cursor: "pointer" }}
                        onClick={() => toast.error("Please login first")}
                      >
                        My Account
                      </ListItem>
                    )}
                  </List>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <Typography variant="h6" color="primary">
                    Faqs
                  </Typography>
                  <List>
                    <ListItem to="/faqs" component={RouterLink}>
                      FAQs
                    </ListItem>
                    <ListItem to="/help-center" component={RouterLink}>
                      Help Center
                    </ListItem>
                    <ListItem to="/feedback" component={RouterLink}>
                      Feedback
                    </ListItem>
                    <ListItem to="/about" component={RouterLink}>
                      About Us
                    </ListItem>
                  </List>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <Box>
                    <Typography variant="h6" color="primary">
                      Contact Us
                    </Typography>
                    <List>
                      <ListItem>
                        <Link href="mailto:info@Jyotirligna.site">
                          <AiOutlineMail />
                          {/* {user?.userData?.userType === "Admin" ? (
                      <> {user?.userData?.email}</>
                    ) : (
                      <>mailto:support@hovr.com</>
                    )} */}
                          info@Jyotirlinga.site
                        </Link>
                      </ListItem>
                    </List>
                  </Box>
                  <Box>
                    <IconButton>
                      <Link target="_blank" href="https://www.facebook.com">
                        <FaFacebookF />
                      </Link>
                    </IconButton>
                    <IconButton>
                      <Link target="_blank" href="https://www.youtube.com">
                        <FiYoutube />
                      </Link>
                    </IconButton>
                    <IconButton>
                      <Link target="_blank" href="https://www.instagram.com">
                        <FaInstagram />
                      </Link>
                    </IconButton>
                    <IconButton>
                      <Link target="_blank" href="https://twitter.com">
                        <FiTwitter />
                      </Link>
                    </IconButton>
                    <IconButton>
                      <Link target="_blank" href="https://discord.com">
                        <AiOutlineReddit style={{ fontSize: "20px" }} />
                      </Link>
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Box className="copy" mt={2}>
          <Container>
            <Box
              mt={1}
              mb={1}
              alignItems="center"
              position="relative"
              flexWrap="wrap"
              display="flex"
              justifyContent="space-between"
            >
              <Box className="displayStart">
                <img
                  src="images/footer_line.png"
                  alt="images"
                  className="borderBox"
                />
                <List className="displayStart">
                  <ListItem
                    to="/terms-conditions"
                    component={RouterLink}
                    style={{ whiteSpace: "pre" }}
                  >
                    Terms & Conditions
                  </ListItem>

                  <ListItem
                    to="/privacy-policy"
                    component={RouterLink}
                    style={{ marginLeft: "30px", whiteSpace: "pre" }}
                  >
                    Privacy Policy
                  </ListItem>
                </List>
              </Box>
              <Typography
                variant="body1"
                style={{ fontWeight: "200", maxWidth: "100%" }}
              >
                Copyright© 2023 Created with love by{" "}
                <span
                  style={{ fontWeight: "bold", textTransform: "uppercase" }}
                >
                  Jyotirlinga
                </span>
              </Typography>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}
