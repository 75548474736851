import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  MenuItem,
  Box,
  Container,
  Typography,
  Menu,
  Paper,
  Dialog,
} from "@material-ui/core";
import Forgot from "src/component/Forgot";
import MainLogin from "src/component/MainLogin";
import Register from "src/component/Register";
import { withStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import { BsCircleHalf, BsSearch } from "react-icons/bs";
import Explorecards from "src/component/Explorecards";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Link, useHistory, useLocation, NavLink } from "react-router-dom";
import Logo from "./../../component/Logo";
import Reset from "src/component/Reset";
import { CgSearch } from "react-icons/cg";
import Verify from "src/component/Verify";
import { ACTIVE_NETWORK, getNetworkDetails } from "src/constants";
import { UserContext } from "src/context/User";
import { FiSun } from "react-icons/fi";
import { FaRegMoon } from "react-icons/fa";
import { useWeb3React } from "@web3-react/core";
import { sortAddress } from "src/utils";
import SearchBox from "src/layouts/HomeLayout/DashboardLayout/SearchBox";
import SettingsContext from "src/context/SettingsContext";
import { BsFillCaretDownFill } from "react-icons/bs";
import { toast } from "react-hot-toast";
import { getWeb3Obj, getContract, swichNetworkHandler } from "src/utils";
import { FaRegCopy } from "react-icons/fa";
// import { toast } from "react-hot-toast";
import CopyToClipboard from "react-copy-to-clipboard";
const headersData = [
  // {
  //   label: "Add Branding",
  //   href: "/add-brand",
  // },
  {
    label: "Marketplace",
    href: "/explore",
  },
  {
    label: "Collections",
    href: "/collections",
  },
  // {
  //   label: "Feature",
  //   href: "/roadmap",
  // },
];

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: "14px",
    lineHeight: "24px",
    fontWeight: "300",
    borderRadius: 0,
    minWidth: "auto",
    color: theme.palette.primary.main,
    padding: "0px 7px",
    textDecoration: " none",
    "@media (max-width: 900px)": {
      fontStyle: "normal",
      letterSpacing: "-0.6px",
      lineHeight: "24px",
      color: "#FFF",
      padding: "15px !important",
      height: "51px",
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    "&.active": {
      color: "#b76722",
    },
    "&:hover": {
      color: "#b76722",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,

    padding: " 5px 0px",
    marginTop: 20,
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    width: "140px",
  },

  drawerContainer: {
    padding: "20px 0px 20px 20px",
    height: "100%",
    background: "#fff",
    background: theme.palette.background.default,
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",

    marginLeft: "0px !important",
    fontSize: "25px",
  },
  logoImg: {
    width: "75px",

    margin: " 14px 15px 11px 0px",
    objectFit: "contain",
    "@media (max-width: 500px)": {
      margin: " 11px 1px 3px 0px",
      width: "52px",
    },
  },
  menuMobile: {
    padding: "16px",
    "@media (max-width: 500px)": {
      padding: "7px 0",
      width: "100%",
    },
  },
  paper1: {
    background: "black",
    color: "white",
  },

  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
  },

  search: {
    height: "40px",
    position: "relative",
    color: "#ABABAB",
    borderRadius: "100px",
    backgroundColor: "#DAF4FF",
    border: "1px solid #fff",

    marginLeft: 20,
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
    },
  },
  searchIcon: {
    fontSize: "16px",
    padding: "0px 9px",
    color: "#000000",
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
    fontSize: "16px",
    width: "100%",
  },
  inputInput: {
    padding: "8px 6px 8px 0px",
    fontSize: "12px",
    marginTop: "-2px",
    paddingLeft: `calc(1em + ${theme.spacing(2)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#000",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      "&:focus": {
        width: "100%",
      },
    },
  },
  menuButton1: {
    paddingLeft: "0",
  },
  searcBox: {
    backgroundColor: "#DAF4FF",
    // border: "1px solid #daf4ff",
    borderRadius: " 50px",
  },
  menuMobile1: {
    // marginLeft: "10px",
    padding: "15px 0",
    "& h4": {
      fontSize: "14px !important",
      lineHeight: " 17px",
      color: theme.palette.text.main,
      margin: "0 8px",
      fontWeight: "400",
      [theme.breakpoints.only("xs")]: {
        fontSize: "12px !important",
      },
    },
    "& svg": {
      color: theme.palette.text.main,
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "& figure": {
      margin: 0,
      width: 40,
      height: 40,
      borderRadius: "50px",
      overflow: "hidden",
      display: "flex",
      justifyContent: " center",
      alignItems: "center",
      "& img": {
        width: "auto",
        height: "auto",
        maxWidth: "100%",
        // maxHeight: "100%",
      },
    },
  },
  menuMobile2: {
    "& h4": {
      fontSize: "14px",
      lineHeight: " 17px",
      color: theme.palette.background.dark,
      margin: "0 5px",
      whiteSpace: "pre",
      fontWeight: "300",
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "& svg": {
      "@media (max-width:767px)": {
        display: "none",
      },
    },
    "&:hover": {
      backgroundColor: "transparent",
      color: "#b76722",
    },
  },
  menuMobiledrawer: {
    "& h4": {
      fontSize: "16px",
      lineHeight: " 17px",
      color: "#000",

      whiteSpace: "pre",
      fontWeight: "400",
    },
  },
  searchdiaogBox: {
    "& .MuiDialogContent-root": {
      minHeight: "calc(100vh - 100px)",
      [theme.breakpoints.only("xs")]: {
        padding: "20px 0 !important",
      },
    },
    "& .MuiDialog-paperScrollPaper": {
      overflowY: "auto",
    },
  },
  logoutDialogContent: {
    padding: "25px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logoutModal: {
    width: "450px",
    [theme.breakpoints.down("xs")]: {
      width: "290px",
    },
  },
}));

export default function Header() {
  const { account, chainId } = useWeb3React();
  const classes = useStyles();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const user = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSignup, setOpenSignup] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [openLogout, setOpenLogout] = useState(false);
  const [openForgot, setOpenForgot] = useState(false);
  const [openVerify, setVerify] = useState(false);
  const [openReset, setOpenReset] = useState(false);

  const handleClose4 = () => {
    setAnchorEl1(null);
  };

  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose5 = () => {
    setAnchorEl2(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose1 = () => {
    setAnchorEl(null);
  };
  const {
    menuMobile,
    menuButton,
    menuButton1,
    divstake,
    searchiconBox,
    themeButton,
    toolbar,
    drawerContainer,
    menuMobile1,
    menuMobile2,
    drawericon,
    logoDrawer,
    mainHeader,
    search,
    inputInput,
    searchIcon,

    inputRoot,
    searcBox,
    menuMobiledrawer,
    marginbtn,
  } = useStyles();
  const history = useHistory();

  const [updateMinSatkeOpen, setUpdateMinSatkeOpen] = useState(false);
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;
  const themeSeeting = useContext(SettingsContext);
  const changeTheme = (type) => {
    themeSeeting.saveSettings({
      theme: type,
    });
  };
  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const [open1, setOpen1] = useState({ community: false, user: false });
  const anchorRef = { community: useRef(null), user: useRef(null) };

  // const handleToggle = (name) => {
  //   setOpen1({ ...open1, [name]: !open1[name] });
  // };

  const StyledMenu = withStyles({
    paper: {
      marginTop: "2px",
    },
  })((props) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      {...props}
    />
  ));

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return;
    }

    setOpen1({ ...open1, [name]: false });
  };

  function handleListKeyDown(event, name) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen1({ ...open1, [name]: false });
    }
  }

  const displayDesktop = () => {
    return (
      // <Container maxWidth="lg" className="p-0">
      <Toolbar className={toolbar}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          {femmecubatorLogo}

          {/* {SearchBoxx} */}
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          {getMenuButtons()}
          {window.sessionStorage.getItem("token") &&
          window.sessionStorage.getItem("token") ? (
            <Button
              aria-label="delete"
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick1}
              className={menuMobile1}
              style={{ marginLeft: "10px" }}
              size="small"
              color="primary"
            >
              <figure
                style={
                  window.sessionStorage.getItem("token") &&
                  window.sessionStorage.getItem("token")
                    ? { height: "40px", width: "40px" }
                    : { width: "0px" }
                }
              >
                <img
                  src={
                    user?.userData?.profilePic
                      ? user?.userData?.profilePic
                      : "/images/idicon.svg"
                  }
                  alt=""
                />
              </figure>
              <Typography
                variant="h4"
                color="primary"
                title={
                  user?.userData?.name
                    ? user?.userData?.name
                    : user?.userData?.walletAddress
                }
              >
                {" "}
                {user?.userData?.name
                  ? user?.userData?.name.slice(0, 5) + ".."
                  : sortAddress(user?.userData?.walletAddress)}
              </Typography>
              <BsFillCaretDownFill style={{ fontSize: "16px" }} />
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              // to="/conect-wallet"
              // component={Link}
              onClick={() => user.setOpenRegister(true)}
              style={{ marginLeft: "15px", whiteSpace: "pre" }}
            >
              Login
            </Button>
          )}
          &nbsp;
          <Box
            className="themeButton"
            style={{ display: "flex", margin: "7px" }}
          >
            <IconButton
              style={{ background: "rgba(255, 255, 255, 0.05)" }}
              // style={{background:"red"}}
              className="searchiconBox"
              onClick={() => setDialogOpen(true)}
            >
              <SearchIcon />
            </IconButton>
          </Box>
          <Box className="themeButton">
            {themeSeeting.settings.theme === "DARK" ? (
              <IconButton
                variant="contained"
                className="searchiconBox1"
                color="primary"
                style={{ background: "rgba(255, 255, 255, 0.05)" }}
                onClick={() => {
                  changeTheme("LIGHT");
                }}
              >
                <FiSun />
              </IconButton>
            ) : (
              <IconButton
                variant="contained"
                className="searchiconBox"
                color="primary"
                style={{ background: "rgba(13, 13, 13, 0.08)" }}
                onClick={() => {
                  changeTheme("DARK");
                }}
              >
                <FaRegMoon />
              </IconButton>
            )}
          </Box>
          <Box className={divstake}>
            <StyledMenu
              id="simple-menu"
              disableScrollLock={true}
              anchorEl={anchorEl1}
              keepMounted
              open={Boolean(anchorEl1)}
              onClose={handleClose4}
            >
              <MenuItem
                onClick={() => {
                  history.push("/profile");
                }}
              >
                Profile
              </MenuItem>
              {user?.userData?.userType === "Admin" && (
                <MenuItem
                  onClick={() => {
                    history.push("/dashboard");
                  }}
                >
                  Dashboard
                </MenuItem>
              )}
              <MenuItem
                onClick={() => {
                  history.push("/character");
                }}
              >
                Select Character
              </MenuItem>
              <MenuItem
                onClick={() => {
                  history.push("/create");
                }}
              >
                Create NFT
              </MenuItem>

              <MenuItem
                onClick={() => {
                  history.push("/KYC");
                }}
              >
                KYC
              </MenuItem>
              <MenuItem onClick={() => setOpenLogout(true)}>Logout</MenuItem>
            </StyledMenu>
          </Box>
        </Box>
      </Toolbar>
      // {/* </Container> */}
    );
  };

  const [searchInput, setSearchInput] = useState("");
  const searchTextRef = React.useRef(null);

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));
    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            <img className={logoDrawer} src="images/Logo.png" alt="" />
            {getDrawerChoices()}
            <div>
              {window.sessionStorage.getItem("token") &&
              window.sessionStorage.getItem("token") ? (
                <IconButton
                  aria-label="delete"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={handleClick1}
                  className={menuMobile1}
                  size="small"
                  style={{ padding: "16px 0px" }}
                >
                  <figure
                    style={
                      user?.isLogin
                        ? { height: "40px", width: "40px" }
                        : { width: "0px" }
                    }
                  >
                    <img
                      src={
                        user?.userData?.profilePic
                          ? user?.userData?.profilePic
                          : "/images/Profile.png"
                      }
                      alt=""
                    />
                  </figure>
                  <Typography
                    variant="h4"
                    color="primary"
                    title={
                      user?.userData?.name
                        ? user?.userData?.name
                        : user?.userData?.walletAddress
                    }
                  >
                    {" "}
                    {user?.userData?.name
                      ? user?.userData?.name.slice(0, 5) + ".."
                      : sortAddress(user?.userData?.walletAddress)}
                  </Typography>
                  <BsFillCaretDownFill
                    style={{
                      color: "#000",
                      fontSize: "16px",
                      marginLeft: "8px",
                    }}
                  />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="delete"
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={() => setUpdateMinSatkeOpen(true)}
                  className={menuMobile1}
                  size="small"
                >
                  <figure
                    style={
                      user?.isLogin
                        ? { height: "40px", width: "40px" }
                        : { width: "0px" }
                    }
                  >
                    <img
                      src={
                        user?.userData?.profilePic
                          ? user?.userData?.profilePic
                          : "/images/Profile.png"
                      }
                      alt=""
                    />
                  </figure>
                  <Button
                    variant="contained"
                    size="large"
                    color="primary"
                    // to="/conect-wallet"
                    // component={Link}
                    onClick={() => user.setOpenRegister(true)}
                    style={{ whiteSpace: "pre", marginTop: "10px" }}
                  >
                    Login
                  </Button>
                </IconButton>
              )}

              <Box className={divstake}>
                <Menu
                  id="simple-menu"
                  disableScrollLock={true}
                  anchorEl={anchorEl1}
                  keepMounted
                  open={Boolean(anchorEl1)}
                  onClose={handleClose4}
                >
                  <MenuItem
                    onClick={() => {
                      history.push("/profile");
                    }}
                  >
                    Profile
                  </MenuItem>
                  {user?.userData?.userType === "Admin" && (
                    <MenuItem
                      onClick={() => {
                        history.push("/dashboard");
                      }}
                    >
                      Dashboard
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      history.push("/character");
                    }}
                  >
                    Select Character
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      history.push("/create");
                    }}
                  >
                    Create NFT
                  </MenuItem>

                  <MenuItem onClick={() => setOpenLogout(true)}>
                    Logout
                  </MenuItem>
                </Menu>
              </Box>
            </div>
          </div>
        </Drawer>

        <Box display="flex" justifyContent="space-between">
          {femmecubatorLogo}
        </Box>
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => setDialogOpen(true)}
            className="searchiconBox"
          >
            <SearchIcon />
          </IconButton>
          <Box className="themeButton">
            {themeSeeting.settings.theme === "DARK" ? (
              <IconButton
                variant="contained"
                color="primary"
                style={{ background: "rgba(255, 255, 255, 0.05)" }}
                onClick={() => {
                  changeTheme("LIGHT");
                }}
              >
                <FiSun />
              </IconButton>
            ) : (
              <IconButton
                variant="contained"
                color="primary"
                style={{ background: "rgba(13, 13, 13, 0.08)" }}
                onClick={() => {
                  changeTheme("DARK");
                }}
              >
                <FaRegMoon />
              </IconButton>
            )}
          </Box>
          <IconButton
            className={drawericon}
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon
              width="60px"
              height="60px"
              style={{ color: "#b76722", fontSize: "26px" }}
            />
          </IconButton>
        </Box>
      </Toolbar>
    );
  };
  //mobile end
  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      );
    });
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );

  const SearchBoxx = (
    <SearchBox
      search={search}
      inputRoot={inputRoot}
      inputInput={inputInput}
      searchIcon={searchIcon}
    />
  );

  const getMenuButtons = (activeClassName) => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          {/* <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button> */}
          <NavLink
            exact
            // to={`${href}`}
            {...{
              key: label,
              color: "inherit",
              to: href,
              // component: Link,
              className: menuButton,
              activeClassName: "active",
            }}
          >
            {" "}
            {label}
          </NavLink>
        </>
      );
    });
  };

  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "relative" : "absolute"}
        elevation={0}
        style={{ backgroundColor: "#ccc0", border: "none" }}
      >
        <Container maxWidth="lg">
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
      {dialogOpen && (
        <Paper>
          <Dialog
            fullWidth
            maxWidth="lg"
            className={classes.searchdiaogBox}
            style={{
              position: "absolute",
              top: "10%",
              // minHeight: "695px",
            }}
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
          >
            <IconButton
              className="closeButton"
              onClick={() => setDialogOpen(false)}
            >
              <CloseIcon style={{ color: "#AAAAAA" }} />
            </IconButton>

            <Box className="dialogBoxHeight">
              <SearchBox />
            </Box>
          </Dialog>
        </Paper>
      )}

      {/* SINGUP MODAL*/}
      <Dialog
        open={openSignup || user?.openRegister}
        // onClose={() => {
        //   // setOpenSignup(false);
        //   user.setOpenRegister(false);
        // }}
        maxWidth="md"
      >
        <Register
          setOpenSignup={(data) => setOpenSignup(data)}
          setOpenForgot={(data) => setOpenForgot(data)}
          user={user}
        />
      </Dialog>

      {/* FORGOT_PASSWORD MODAL*/}
      <Dialog
        open={openForgot}
        onClose={() => {
          // setOpenForgot(false);
        }}
        maxWidth="md"
      >
        <Forgot
          setOpenForgot={(data) => setOpenForgot(data)}
          setOpenLogin={(data) => setOpenSignup(data)}
          setVerify={(data) => setVerify(data)}
          themeSeeting={themeSeeting}
        />
      </Dialog>

      {/* VERIFY_OTP MODAL*/}
      <Dialog
        open={openVerify}
        onClose={() => {
          // setVerify(false);
        }}
        maxWidth="md"
      >
        <Verify
          setVerify={(data) => setVerify(data)}
          setOpenReset={(data) => setOpenReset(data)}
          themeSeeting={themeSeeting}
        />
      </Dialog>

      {/* RESET_PASSWORD MODAL*/}
      <Dialog open={openReset} onClose={() => {}} maxWidth="md">
        <Reset
          setOpenReset={(data) => setOpenReset(data)}
          setOpenLogin={(data) => setOpenSignup(data)}
          themeSeeting={themeSeeting}
        />
      </Dialog>

      {/* LOGOUT MODAL*/}
      <Dialog open={openLogout} onClose={() => setOpenLogout(false)}>
        <Box className={`${classes.logoutModal} newModalBorder`}>
          <Box
            className={
              themeSeeting?.settings?.theme === "DARK" ? "mainbox" : "mainbox1"
            }
          >
            <div
              className={
                themeSeeting?.settings?.theme === "DARK"
                  ? "logindarkleftModal"
                  : "loginlightModal"
              }
            ></div>
            <div
              className={
                themeSeeting?.settings?.theme === "DARK"
                  ? "loginDarktModal"
                  : "loginlightrightModal"
              }
            ></div>
            <Box className={classes.logoutDialogContent}>
              <Typography
                variant="h3"
                color="primary"
                style={{ fontSize: "25px" }}
              >
                Logout!
              </Typography>

              <Box my={2}>
                <Typography
                  variant="h4"
                  color="primary"
                  style={{ whiteSpace: "pre", fontSize: "16px" }}
                >
                  Do you want to logout?
                </Typography>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => setOpenLogout(false)}
                  >
                    No
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    style={{ marginLeft: "8px" }}
                    onClick={() => {
                      setAnchorEl1();
                      user.logoutHandler();
                    }}
                  >
                    Yes
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
