import {
  makeStyles,
  Box,
  Container,
  Typography,
  TextField,
  FormHelperText,
  Grid,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  IconButton,
  Dialog,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import React, { useState, useContext, useEffect } from "react";
import { Form, Formik } from "formik";
import * as yep from "yup";
import Forgot from "src/component/Forgot";
import { useHistory } from "react-router-dom";
import SettingsContext from "src/context/SettingsContext";
import "react-phone-input-2/lib/style.css";
import Logo from "src/component/Logo";
import { dataPostHandler } from "src/services/index";
import ButtonCircularProgress from "src/component/ButtonCircularProgress";
import { toast } from "react-hot-toast";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { UserContext } from "src/context/User";
import CloseIcon from "@material-ui/icons/Close";
import Verify from "src/component/Verify";
const useStyles = makeStyles((theme) => ({
  mainSignupFrom: {
    padding: "30px 0px",
    position: "relative",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    zIndex: "1111",
    "& h4": {
      fontSize: "18px",
      marginBottom: "24px",
      // color:"#fff",
      "@media(max-width:768px)": {
        fontSize: "14px",
      },
    },
    "& .logoSection": {
      paddingBottom: "64px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
    },
    "@media(max-width:768px)": {
      paddingLeft: "0px",
    },
  },

  HeaddingBox: {
    marginTop: "5px",
    "@media(max-width:960px)": {
      textAlign: "center",
    },
  },
  formikBox: {
    marginTop: "15px",
  },

  Register: {
    "& h4": {
      // color: "#fff",
    },
    "@media(max-width:960px)": {
      textAlign: "center",
    },
  },

  FormInputField: {
    border: "none",
    color: "#fff",
    "& input": {
      marginTop: "8px",
      marginBottom: "4px",
      // color: "#fff",
    },

    "& .MuiOutlinedInput-input": {
      borderRadius: "0px",
    },

    "& svg": {
      // color: "#fff",
      cursor: "pointer",
    },
  },
  bodyttitle: {
    "& body1": {
      color: "red",
    },
  },
}));

export default function ({ setOpenForgot, setOpenSignup, setLoginpage }) {
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext);
  const themeSeeting = useContext(SettingsContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errmsg, setErrMsg] = useState("");
  const [openVerify, setVerify] = useState(false);
  const [isRememberMe, setIsRememberMe] = useState(false);

  useEffect(() => {
    if (
      window.sessionStorage.getItem("password") ||
      window.sessionStorage.getItem("email")
    ) {
      setIsRememberMe(true);
    } else {
      setIsRememberMe(false);
    }
  }, [
    window.sessionStorage.getItem("email"),
    window.sessionStorage.getItem("password"),
  ]);

  const handlechangeopen = () => {
    setOpenForgot(true);
    setOpenSignup(false);
  };

  function rememberMe() {
    if (!isRememberMe) {
      setIsRememberMe(true);
      const email = document.getElementById("email");
      const password = document.getElementById("password");
      window.localStorage.setItem("email", email?.value);
      window.localStorage.setItem("password", password?.value);
    } else {
      setIsRememberMe(false);
      window.localStorage.removeItem("email");
      window.localStorage.removeItem("password");
    }
  }

  const formInitialSchema = {
    email: localStorage.getItem("email") ? localStorage.getItem("email") : "",
    password: sessionStorage.getItem("password")
      ? sessionStorage.getItem("password")
      : "",
  };
  const formValidationSchema = yep.object().shape({
    email: yep
      .string()
      .email("Please enter a valid email address.")
      .required("Email is required."),
    password: yep.string().required("Password is required."),
  });
  const handleFormSubmit = async (values) => {
    window.sessionStorage.setItem("email", values.email);
    if (isRememberMe) {
      window.sessionStorage.setItem("password", values.password);
    }
    try {
      setIsSubmitting(true);
      const response = await dataPostHandler("login", {
        email: values.email,
        password: values.password,
      });
      if (
        response?.data !== undefined &&
        response?.data?.statusCode !== undefined
      ) {
        if (response.data.statusCode == 200) {
          if (response.data.result.otpVerification) {
            window.sessionStorage.setItem("token", response.data.result.token);
            window.sessionStorage.setItem(
              "userType",
              response.data.result.userType
            );
            setOpenSignup(false);
            user.getProfileHandler(response.data.result.token);
            user.setOpenRegister(false);
            toast.success(response.data.responseMessage);
            setIsSubmitting(false);
          } else {
            toast.erroring(response.data.responseMessage);
            setIsSubmitting(false);
            setVerify(true);
          }
        } else {
          setIsSubmitting(false);
        }
      } else {
        setIsSubmitting(false);
        setErrMsg(response);
      }
    } catch (error) {
      console.log("error", error);
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      className={
        themeSeeting?.settings?.theme === "DARK" ? "mainbox" : "mainbox1"
      }
    >
      <Box className={classes.mainSignupFrom}>
        <div className="shade13"></div>

        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item lg={6} sm={6} xs={12}>
              <Box className="overlay-container">
                <Box className="leftPanelAuth">
                  <img
                    src="images/login.png"
                    alt=""
                    className="avatar"
                    style={{ width: "100%", maxWidth: "300px" }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item lg={6} sm={6} xs={12}>
              <Box className={classes.Register}>
                <Typography
                  variant="h4"
                  color="primary"
                  style={{ fontSize: "30px" }}
                >
                  Login
                </Typography>
              </Box>

              <Box className={classes.HeaddingBox}></Box>
              <Box className={classes.formikBox}>
                <Formik
                  initialValues={formInitialSchema}
                  initialStatus={{
                    success: false,
                    successMsg: "",
                  }}
                  validationSchema={formValidationSchema}
                  onSubmit={(values) => handleFormSubmit(values)}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    touched,
                    values,
                    setFieldValue,
                  }) => (
                    <Form className={classes.root}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <FormControl fullWidth className={classes.margin}>
                            <TextField
                              variant="outlined"
                              type="email"
                              placeholder="Email address"
                              fullWidth
                              name="email"
                              id="email"
                              value={values.email}
                              error={Boolean(touched.email && errors.email)}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              className={classes.FormInputField}
                            />

                            <FormHelperText
                              error
                              className={classes.helperText}
                            >
                              {touched.email && errors.email}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <FormControl fullWidth className={classes.margin}>
                            <TextField
                              type={showPassword ? "text" : "password"}
                              placeholder="Password"
                              variant="outlined"
                              fullWidth
                              name="password"
                              id="password"
                              value={values.password}
                              error={Boolean(
                                touched.password && errors.password
                              )}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              className={classes.FormInputField}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      onClick={() =>
                                        setShowPassword(!showPassword)
                                      }
                                      edge="end"
                                    >
                                      <Box className={classes.passsec}>
                                        {showPassword ? (
                                          <VisibilityIcon
                                            className={classes.iconsclass1}
                                          />
                                        ) : (
                                          <VisibilityOffIcon
                                            className={classes.iconsclass1}
                                          />
                                        )}
                                      </Box>
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                            <FormHelperText
                              error
                              className={classes.helperText}
                            >
                              {touched.password && errors.password}
                            </FormHelperText>
                          </FormControl>
                          <Box display="flex" alignItems="center">
                            <FormControlLabel
                              // style={{padding:"10px 0"}}
                              control={
                                <Checkbox
                                  name="checkedC"
                                  checked={isRememberMe}
                                  onClick={rememberMe}
                                />
                              }
                              // label="Remember me"
                              className={classes.ForgetpassBox}
                            />
                            <Typography
                              color="primary"
                              style={{ marginLeft: "-15px" }}
                            >
                              Remember me
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Box mb={2}>
                            <Button
                              type="submit"
                              variant="contained"
                              fullWidth
                              color="primary"
                              style={{ marginTop: "2px" }}
                              disabled={isSubmitting}
                            >
                              {isSubmitting ? (
                                <>
                                  Please wait... <ButtonCircularProgress />
                                </>
                              ) : (
                                "Login"
                              )}
                            </Button>
                            {errmsg !== "" && (
                              <FormHelperText error>{errmsg}</FormHelperText>
                            )}
                          </Box>
                          <Box
                            display="flex"
                            justifyContent="center"
                            width="100%"
                          >
                            <Typography
                              variant="h6"
                              color="primary"
                              style={{
                                textAlign: "center",

                                cursor: "pointer",
                                marginRight: "12px",
                                marginBottom: "0px",
                              }}
                              onClick={() => setLoginpage(false)}
                            >
                              Sign up&nbsp; |
                            </Typography>
                            <Typography
                              variant="h6"
                              color="primary"
                              style={{
                                textAlign: "center",

                                cursor: "pointer",
                                marginRight: "12px",
                                marginBottom: "0px",
                              }}
                              onClick={handlechangeopen}
                            >
                              Forgot password?
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
