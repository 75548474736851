import React, { useContext } from "react";
import SettingsContext from "src/context/SettingsContext";


const Logo = (props) => {
  const themeSeeting = useContext(SettingsContext);
  return (
    <img
      className="logoimageBox"
      src={
        themeSeeting.settings.theme === "DARK"
          ? "./images/Logo.png"
          : "./images/Logo2.png"
      }
      // src="/images/Logo.png"
      alt="Logo"
      {...props}
      style={{ width: "auto", maxWidth: "190px" }}
    />
  );
};

export default Logo;
