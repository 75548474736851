import React from "react";
import {
  makeStyles,
  Box,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import Login from "src/views/pages/Auth/Login";
// import AnimateLogo from "./AnimateLogo";

const useStyles = makeStyles((theme) => ({
  mainSection: {
    width: "100%",
    // height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#1B0F20",
    "@media(max-width:600px)": {
      height: "auto",
    },
  },

  earth: {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    "@media(max-width:960px)": {
      display: "none",
    },
  },
}));
export default function MainLogin({
  setOpenLogin,
  setOpenForgot,
  setOpenSignup,
  themeSeeting,
}) {
  const classes = useStyles();

  return (
    <Box className="newModalBorder">
      <Box
        className={
          themeSeeting?.settings?.theme === "DARK" ? "mainbox" : "mainbox1"
        }
      >
        <Container>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} sm={12} md={5} lg={5}>
              <Box className={classes.earth}>
                <img src="images/login.png" alt="" width="100%" />
                {/* <AnimateLogo /> */}
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={7} lg={7}>
              <Box className={classes.Rightpart}>
                <Login
                  setOpenLogin={(data) => setOpenLogin(data)}
                  setOpenForgot={(data) => setOpenForgot(data)}
                  setOpenSignup={(data) => setOpenSignup(data)}
                />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}
