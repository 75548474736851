import React from "react";
import {
  FacebookShareButton,
  TelegramShareButton,
  EmailShareButton,
  TwitterShareButton,
} from "react-share";
import { Box, Button, Grid, IconButton, Typography } from "@material-ui/core";
import List from "@material-ui/core/List";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { makeStyles } from "@material-ui/styles";
import { MdEmail } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  ShareSocialMedia: {
    "& .socialBox": {
      border: "1px solid #80808042",
      borderRadius: "10px",
      padding: "16px",
      [theme.breakpoints.only("xs")]: {
        padding: "6px",
      },

      "& .iconShareButton": {
        background: "rgba(255, 255, 255, 0.05)",
      },

      "& p": {
        marginTop: "10px",
      },
      "&:hover": {
        background: "rgba(255, 255, 255, 0.1)",
        "& .iconShareButton": {
          // background: "#5D5D5D",

          background: theme.palette.background.blur,
        },
      },
    },
  },
}));
export default function ShareSocialMedia({ url }) {
  const classes = useStyles();
  return (
    <Box className={classes.ShareSocialMedia}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box className="socialBox">
            <FacebookShareButton url={url} target="_blank">
              <IconButton className="iconShareButton">
                {" "}
                <FaFacebookF style={{ fontSize: "23px" }} />
              </IconButton>
              <br />
              <Typography variant="body2" color="primary">
                Facebook
              </Typography>
            </FacebookShareButton>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="socialBox">
            <EmailShareButton
              url={url}
              email="info@Jyotirlinga.site"
              subject="Check out this item on Jyotirlinga"
              className="Demo_some-network__share-button"
            >
              <IconButton className="iconShareButton">
                <MdEmail style={{ fontSize: "23px" }} />
              </IconButton>
              <br />
              <Typography variant="body2" color="primary">
                {" "}
                E-mail
              </Typography>
            </EmailShareButton>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="socialBox">
            <TelegramShareButton
              url={url}
              title={"Check out this item on Jyotirlinga"}
            >
              <IconButton className="iconShareButton">
                <FaTelegramPlane style={{ fontSize: "23px" }} />
              </IconButton>
              <br />
              <Typography variant="body2" color="primary">
                {" "}
                Telegram
              </Typography>
            </TelegramShareButton>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="socialBox">
            <TwitterShareButton
              url={url}
              title={`Check out this item on Jyotirlinga`}
              // hashtag="#camperstribe"
            >
              <IconButton className="iconShareButton">
                {" "}
                <FaTwitter style={{ fontSize: "23px" }} />
              </IconButton>
              <br />
              <Typography variant="body2" color="primary">
                {" "}
                Twitter
              </Typography>
            </TwitterShareButton>{" "}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
