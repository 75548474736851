import React, { useState, useContext } from "react";
import {
  makeStyles,
  Box,
  Container,
  Grid,
  Typography,
  IconButton,
} from "@material-ui/core";
import Logo from "./Logo";
import Signup from "./Signup";
import PageLoading from "./PageLoading";
// import AnimateLogo from "./AnimateLogo";
import Login from "src/views/pages/Auth/Login";
import CloseIcon from "@material-ui/icons/Close";
import SettingsContext from "src/context/SettingsContext";
const useStyles = makeStyles((theme) => ({
  mainSection: {
    background: "#1B0F20",
  },

  earth: {
    overflow: "hidden",
    display: "flex",
    justifyContent: "center",
    "@media(max-width:960px)": {
      display: "none",
    },
  },
  container: {
    position: "relative",
    overflow: "hidden",
    width: "678px",
    maxWidth: "100%",
    minHeight: "400px",
  },
  leftSideHeight: {
    display: "flex",
    alignItems: "center",
    minHeight: "550px",
    position: "relative",
    "@media(max-width:768px)": {
      minHeight: "316px",
    },
  },
  rightSideHeight: {
    minHeight: "550px",
    "@media(max-width:768px)": {
      // minHeight: "700px",
    },
  },
  desktopView: {
    "@media(min-width:768px)": {
      width: "813px",
      maxWidth: "100%",
    },
  },
}));
export default function Register({ setOpenSignup, setOpenForgot, user }) {
  const classes = useStyles();
  const [loginpage, setLoginpage] = useState(true);
  const themeSeeting = useContext(SettingsContext);

  const UpdateModal = () => {
    setOpenSignup(false);
    user.setOpenRegister(false);
  };

  return (
    <Box className={[classes.desktopView, "newModalBorder"]}>
      <Box
        className={
          themeSeeting?.settings?.theme === "DARK" ? "mainbox" : "mainbox1"
        }
      >
        {/* <Container> */}
        <Box display="flex" justifyContent="flex-end" width="100%">
          <IconButton
            style={{ cursor: "pointer" }}
            className="closeIcon"
            onClick={UpdateModal}
          >
            <CloseIcon style={{ fontSize: "20px" }} />
          </IconButton>
        </Box>
        <Box classes={classes.leftSideHeight} id="container">
          <Box className="form-container sign-in-container">
            {loginpage ? (
              <>
                <Login
                  setOpenSignup={(data) => setOpenSignup(data)}
                  setOpenForgot={(data) => setOpenForgot(data)}
                  setLoginpage={(data) => setLoginpage(data)}
                />
              </>
            ) : (
              <>
                <Signup
                  setOpenSignup={(data) => setOpenSignup(data)}
                  setLoginpage={(data) => setLoginpage(data)}
                />
              </>
            )}
          </Box>
        </Box>
        {/* </Container> */}
      </Box>
    </Box>
  );
}
